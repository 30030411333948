import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Space`}</h1>
    <p>{`The distance between elements and how these proportions interact in the design.`}</p>
    <h2>{`Usage`}</h2>
    <p><strong parentName="p">{` Padding and margin are relative to the base size of `}<inlineCode parentName="strong">{`16px`}</inlineCode>{` or `}<inlineCode parentName="strong">{`1em`}</inlineCode>{`.`}</strong></p>
    <p>{`Establish visual cohesion by applying consistent spacing in layouts and components.`}</p>
    <p>{`Apply these proportions through:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/using-spark/components/box"
        }}>{`Box`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/using-spark/components/stack"
        }}>{`Stack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/using-spark/foundations/css-utilities#spacing"
        }}>{`Spacing Utilities`}</a></li>
    </ul>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`There are five built in spacing sizes:`}<ul parentName="li">
          <li parentName="ul">{`Extra Small (`}<inlineCode parentName="li">{`4px`}</inlineCode>{`, `}<inlineCode parentName="li">{`.25rem`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Small (`}<inlineCode parentName="li">{`8px`}</inlineCode>{`, `}<inlineCode parentName="li">{`.5rem`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Medium (`}<inlineCode parentName="li">{`16px`}</inlineCode>{`, `}<inlineCode parentName="li">{`1rem`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Large (`}<inlineCode parentName="li">{`32px`}</inlineCode>{`, `}<inlineCode parentName="li">{`2rem`}</inlineCode>{`)`}</li>
          <li parentName="ul">{`Extra Large (`}<inlineCode parentName="li">{`64px`}</inlineCode>{`, `}<inlineCode parentName="li">{`4rem`}</inlineCode>{`)`}</li>
        </ul></li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure sprk-u-mvn" mdxType="SprkDivider"></SprkDivider>
    <h3>{`Table of Contents`}</h3>
    <ul>
  <li>
    Padding
    <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
      <li>
        <a href="#inset">
          Inset
        </a>
      </li>
      <li>
        <a href="#inset-short">
          Inset Short
        </a>
      </li>
      <li>
        <a href="#inset-tall">
          Inset Tall
        </a>
      </li>
    </ul>
  </li>
  <li>
    Margin
    <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
      <li>
        <a href="#stack">
          Stack
        </a>
      </li>
      <li>
        <a href="#inline">
          Inline
        </a>
      </li>
      <li>
        <a href="#misc-sizes">
          Misc Sizes
        </a>
      </li>
    </ul>
  </li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure sprk-u-mvn" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Inset`}</h3>
    <p>{`Adds equal padding to all four sides of an element.`}</p>
    <blockquote>
      <p parentName="blockquote">{`For illustrative purposes, gray represents the padding of the element. It will not appear in your project.`}</p>
    </blockquote>
    <ComponentPreview componentName="spacing--inset-spacing" componentType="foundations" titleAttr="Inset Spacing Example" hasHTML mdxType="ComponentPreview" />
    <h3>{`Inset Short`}</h3>
    <p>{`Similar to `}<a parentName="p" {...{
        "href": "#inset"
      }}>{`Inset`}</a>{`, except the
top and bottom padding is reduced by 50%.`}</p>
    <p>{`For example, Inset Short Small has `}<inlineCode parentName="p">{`8px`}</inlineCode>{` of padding
on the left and right sides, but `}<inlineCode parentName="p">{`4px`}</inlineCode>{` padding
on the top and bottom.`}</p>
    <blockquote>
      <p parentName="blockquote">{`For illustrative purposes, gray represents the padding of the element. It will not appear in your project.`}</p>
    </blockquote>
    <ComponentPreview componentName="spacing--inset-spacing-short" componentType="foundations" titleAttr="Inset Short Spacing Example" hasHTML mdxType="ComponentPreview" />
    <div className="sprk-u-Measure sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Inset Tall`}</h3>
    <p>{`Similar to `}<a parentName="p" {...{
        "href": "#inset"
      }}>{`Inset`}</a>{`, except the
top and bottom padding is increased by 50%. For
example, Inset Tall Small has `}<inlineCode parentName="p">{`8px`}</inlineCode>{` padding on the
left and right sides, but `}<inlineCode parentName="p">{`12px`}</inlineCode>{` padding on the
top and bottom.`}</p>
    <blockquote>
      <p parentName="blockquote">{`For illustrative purposes, gray represents the padding of the element. It will not appear in your project.`}</p>
    </blockquote>
    <ComponentPreview componentName="spacing--inset-spacing-tall" componentType="foundations" titleAttr="Inset Tall Spacing Example" hasHTML mdxType="ComponentPreview" />
    <div className="sprk-u-Measure sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Stack`}</h3>
    <p>{`Adds margin to the bottom of elements.`}</p>
    <blockquote>
      <p parentName="blockquote">{`For illustrative purposes, gray represents the margin of the element. It will not appear in your project.`}</p>
    </blockquote>
    <ComponentPreview componentName="spacing--stack-spacing" componentType="foundations" titleAttr="Stack Spacing Example" hasHTML mdxType="ComponentPreview" />
    <div className="sprk-u-Measure sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Inline`}</h3>
    <p>{`Adds right margin to inline elements.`}</p>
    <blockquote>
      <p parentName="blockquote">{`For illustrative purposes, gray represents the margin of the element. It will not appear in your project.`}</p>
    </blockquote>
    <ComponentPreview componentName="spacing--inline-spacing" componentType="foundations" titleAttr="Inline Spacing Example" hasHTML mdxType="ComponentPreview" />
    <div className="sprk-u-Measure sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>
    <h3>{`Misc Sizes`}</h3>
    <p>{`Additional sizing proportions to supplement existing XS-XL sizes:`}</p>
    <ul>
      <li parentName="ul">{`Misc A (`}<inlineCode parentName="li">{`24px`}</inlineCode>{`, `}<inlineCode parentName="li">{`1.5rem`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Misc B (`}<inlineCode parentName="li">{`40px`}</inlineCode>{`, `}<inlineCode parentName="li">{`2.5rem`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Misc C (`}<inlineCode parentName="li">{`48px`}</inlineCode>{`, `}<inlineCode parentName="li">{`3rem`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Misc D (`}<inlineCode parentName="li">{`80px`}</inlineCode>{`, `}<inlineCode parentName="li">{`5rem`}</inlineCode>{`)`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`For illustrative purposes, gray represents the margin of the element. It will not appear in your project.`}</p>
    </blockquote>
    <ComponentPreview componentName="spacing--inline-spacing-misc" componentType="foundations" titleAttr="Miscellaneous Spacing Example" hasHTML mdxType="ComponentPreview" />
    <div className="sprk-u-Measure sprk-u-TextAlign--right sprk-u-mbm">
  <a className="sprk-b-Link" href="#table-of-contents">
    Back to Table of Contents
  </a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      